<template>
  <div class="grid">
    <div class="col-12">
      <div class="font-medium text-3xl text-900 mb-3">
        <span class="text-primary text-5xl">/</span> {{ $route.meta.title }}
      </div>

      <div class="card">
        <DataTable dataKey="_id" :value="records" v-model:expandedRows="expandedRows" :row-hover="true"
          responsiveLayout="stack" breakpoint="640px">

          <template #header>
            <div class="table-header-container">
              <Button icon="pi pi-plus" label="Expandir todos" @click="expandAll" class="mr-2" />
              <Button icon="pi pi-minus" label="Reduzir todos" @click="collapseAll" />
            </div>
          </template>

          <template #empty> Nenhum registro encontrado. </template>

          <template #loading> Carregando registros. Aguarde ... </template>

          <Column :expander="true" headerStyle="width: 3rem" />

          <Column field="documento" header="Documento">
            <template #body="{ data }">
              {{ $filters.cpfCnpj(data.documento) }}
            </template>
          </Column>

          <Column field="nome" header="Nome"></Column>

          <Column field="origem" header="Origem alteração"></Column>

          <Column field="updatedAt" header="Data alteração" :sortable="true">
            <template #body="{ data }">
              {{ $filters.dateTime(data.updatedAt) }}
            </template>
          </Column>

          <template #expansion="{ data }">
            <DataTable :value="data.telefones" class="p-datatable-sm" responsiveLayout="scroll">
              <template #header>
                Telefones
              </template>
              <Column field="telefone" header="Número"></Column>
              <Column field="tipo" header="Tipo"></Column>
              <Column field="principal" header="Principal"></Column>
              <Column field="validado" header="Validado"></Column>
            </DataTable>

            <DataTable :value="data.enderecos" class="p-datatable-sm mt-3" responsiveLayout="scroll">
              <template #header>
                Endereços
              </template>
              <Column field="logradouro" header="Logradouro"></Column>
              <Column field="complemento" header="Complemento"></Column>
              <Column field="tipo" header="Tipo"></Column>
              <Column field="principal" header="Principal"></Column>
            </DataTable>

            <DataTable :value="data.emails" class="p-datatable-sm mt-3" responsiveLayout="scroll">
              <template #header>
                E-mails
              </template>
              <Column field="email" header="E-mail"></Column>
              <Column field="tipo" header="Tipo"></Column>
              <Column field="principal" header="Principal"></Column>
              <Column field="validado" header="Validado"></Column>
            </DataTable>
          </template>

        </DataTable>

        <div class="flex mt-5">
          <Button label="Voltar" icon="pi pi-times" class="p-button-text w-3 ml-auto" @click="$router.push(-1)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import List from '../../components/abstract/List.vue'
import CrudService from '@/services/crud'

export default {
  extends: List,
  data() {
    return {
      service: new CrudService(`/admin/devedores/${this.$route.params.documento}/history`),
      sort: 'updatedAt DESC',
      expandedRows: []
    }
  },
  methods: {
    expandAll() {
      this.expandedRows = this.records.filter(r => r._id);
    },
    collapseAll() {
      this.expandedRows = null;
    }
  }
}
</script>

<style scoped lang="scss">
</style>
